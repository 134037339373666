import { ArrowLeftIcon, PrinterIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import { useStore } from "../app/store/store";
import { generateBarcode, generateQr } from "../components/helpers";

const Etiqueta = ({ location }) => {
  const [globalState] = useStore();
  const _guideTypes = {
    PP: "Puerta a puerta",
    PA: "Puerta a Aeropuerto",
    AA: "Aeropuerto a Aeropuerto",
  };
  /* console.log("ori",globalState.guide.origen.nombre)
  console.log("des", globalState.guide.destino.nombre)
   */
  return (
    <>
      <Helmet>
        <title>Etiqueta de guía</title>
      </Helmet>
      {
        globalState.guide && globalState.guide._id && (
          <div className="max-w-xs mx-auto my-12 flex flex-wrap p-4">
            <div className="flex-col mb-12">
              <img
                src={generateBarcode(globalState.guide._id)}
                alt="barcode"
                className="w-full mb-12"
              />
              <img
                src={generateQr(globalState.guide._id)}
                alt="qrcode"
                className="w-full mb-2"
              />
              <div className="w-full text-center font-semibold text-base">
                [{" "}
                {globalState.guide.nodos
                  .filter((el) => el !== "N/A")
                  .filter((el) => el !== "")
                  .join(", ")}{" "}
                ]
              </div>
            </div>
            <div className="py-6 text-base font-display">
              <span className=" font-bold  uppercase text-primary-gray">
                tipo de servicio:
              </span>
              &nbsp; {_guideTypes[globalState.guide.servicio] || ""}
              <br />
              <span className=" font-bold  uppercase text-primary-gray">
                peso:
              </span>
              &nbsp; {globalState.guide.paquete.peso} kgs
              <br />
              <span className=" font-bold  uppercase text-primary-gray">
                ruta:
              </span>
              {/* get route from somewhere else */}
              &nbsp; {globalState.guide.ruta}
              <br />
              <span className=" font-bold  uppercase text-primary-gray">
                costo:
              </span>
              &nbsp; $ {parseFloat(globalState.guide.costo).toFixed(2)} MXN
              <br />
            </div>
            <div className="uppercase text-base">
              <span className=" font-bold text-primary-gray">
                Consignatario:
              </span>
              <br />
              Nombre : &nbsp; {globalState.guide.destino.nombre}
              <br />
              Dirección :
              <span>
                {" "}
                {`${globalState.guide.destino.calle} ${
                  globalState.guide.destino.exterior
                } ${globalState.guide.destino.interior || ""}`}
              </span>
              <br />
              <span>
                {`${globalState.guide.destino.colonia}, ${globalState.guide.destino.cp}`}
                <br />
                {`
                  ${globalState.guide.destino.ciudad}, ${globalState.guide.destino.municipio},
                  ${globalState.guide.destino.estado}`}
              </span>
              <br />
              Teléfono : &nbsp; {globalState.guide.destino.telefono}
              <br />
              <br />
              <span className=" font-bold text-primary-gray">
                fecha estimada de entrega:
              </span>
              &nbsp; {globalState.guide.fecha_entrega}
              <br />
              <span className=" font-bold text-primary-gray">
                Número de guía:
              </span>
              &nbsp; {globalState.guide._id}
            </div>
            <div className="print:hidden  my-8 flex space-x-4 justify-around justify-items-center align-middle">
              <Link
                to="/pago-completado"
                className="btn-shape bg-primary-light-red text-white"
              >
                <ArrowLeftIcon className="w-4 h-4 stroke-current mr-1" />
                Regresar
              </Link>
              <div
                className="btn-shape mx-auto bg-primary-light-green text-white cursor-pointer"
                onClick={() => window.print()}
                role="button"
              >
                <PrinterIcon className="w-4 h-4 stroke-current mr-1" />
                Imprimir
              </div>
            </div>
          </div>
        )
        // end has guide
      }
    </>
  );
};

export default Etiqueta;
